import { WalletActivityFilter } from "~/models/__constants__";
import { Chain } from "~/types";
import { cn } from "~/utils/cn";
import { bigNumberConverter2 } from "~/utils/convertDecimals";

const getTextAndColor = (type: WalletActivityFilter) => {
  switch (type) {
    case WalletActivityFilter.BURN:
      return { color: "bg-red-500", text: "Burn" };
    case WalletActivityFilter.BUY:
      return { color: "bg-emerald-800", text: "Buy" };
    case WalletActivityFilter.MINT:
      return { color: "bg-sky-800", text: "Mint" };
    case WalletActivityFilter.SELL:
      return { color: "bg-rose-800", text: "Sell" };
    case WalletActivityFilter.TRANSFER_FROM:
      return { color: "bg-gray-700", text: "Transfer" };
    case WalletActivityFilter.TRANSFER_TO:
      return { color: "bg-gray-700", text: "Receive" };
    case WalletActivityFilter.UNKNOWN:
      return { color: "bg-gray-500", text: "Unkown" };
  }
};

export const WalletActivityBadge = ({
  type,
  quantity,
  price,
}: {
  type: WalletActivityFilter;
  quantity: number;
  price?: {
    value: string;
    currency: string;
  };
}) => {
  const q = quantity > 1 ? quantity + " " : "";
  const { text, color } = getTextAndColor(type);

  if (price)
    return (
      <div className="flex items-center gap-[1px] text-gray-200 text-xs font-semibold">
        <div
          className={cn(
            color,
            "px-2 rounded-s-md min-w-[26px] h-6 flex items-center justify-center"
          )}
        >
          {q} {text}
        </div>

        <div
          className={cn(
            color,
            "gap-0.5 pl-1 pr-2 rounded-e-md min-w-[26px] h-6 flex items-center justify-center"
          )}
        >
          {bigNumberConverter2(price.value)}
          <div className="text-gray-300 text-[10px]">
            {price.currency}
            {/* {price.chain === Chain.Bitcoin ? "BTC" : "ETH"} */}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="flex items-center gap-[1px] text-gray-200 text-xs font-semibold">
        <div
          className={cn(color, "px-2 rounded-md min-w-[26px] h-6 flex items-center justify-center")}
        >
          {text}
        </div>
      </div>
    );
};
